import ApiService from './api.service';

export const UserRoleService = {
  query(params) {
    return ApiService.query('user_roles', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('user_roles', slug);
  },
  create(params) {
    return ApiService.post('user_roles', { user_role: params });
  },
  update(slug, params) {
    return ApiService.update('user_roles', slug, { user_role: params });
  },
  destroy(slug) {
    return ApiService.delete(`user_roles/${slug}`);
  },
};

export default UserRoleService;
